import React from "react"
import { graphql } from 'gatsby'

import Header from "../components/header/header"

import '../pages/reset.css'

export default function BlogArticleTemplate(props) {

  let { article } = props.pageContext;

  console.log(article)

  return (
    <div style={{textAlign: "center", margin: "0", padding: "0"}}>
      
      <Header />
      {article.data.title.text}                

    </div>)
  
}

export const query = graphql`
  {  
    allPrismicBlogArticle {
      nodes {
        data {
          body {
            text
          }
          subtitle {
            text
          }
          title {
            text
          }
        }
      }
    }
  }
`